@use 'styles/theme/theme' as theme;

.planCard {
  width: 30%;
  max-width: 215px;

  border-radius: 5px;
  border-radius: 6px 6px 0px 0px;
  position: relative;
  border: 1px solid theme.$borderGrey;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__actionText {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    height: auto;
    max-width: 100%;
    &__price {
      line-height: 1.5;
    }
    &__featuresRow{
      &__icon{
        text-align: center !important;
      }
    }
    &__headerBottom_mobile {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 2px;
      margin-top: 3px;
      button {
        span {
          text-align: left;
          word-wrap: break-word;
          overflow-wrap: normal;
          white-space: normal;
        }
      }
    }
  }
  &__action_top {
    button {
      font-size: 16px;
    }
  }
  &__priceWrapper {
    display: flex;
  }
  &__header {
    border-radius: 6px 6px 0px 0px;
    background: linear-gradient(180deg, theme.$whiteT0 0%, theme.$whiteT50 100%);
    padding-top: 10px;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    padding-bottom: 20px;
    &_expanded {
    }
    @media screen and (max-width: 1024px) {
      border-radius: 6px;
    }
  }
  &__mobileExpandIcon {
    position: absolute;
    right: 20px;
    bottom: 8px;
    button {
      font-size: 12px;
      color: theme.$primaryGrey;
      text-decoration: underline;
    }
    svg {
      fill: theme.$primaryGrey;
    }
  }
  &__description {
    max-width: 70px;
    @media screen and (max-width: 1024px) {
      margin-top: 0;
    }
    margin-top: 2px;
  }
  &__description_fullWidth {
    width: 100%;
    max-width: 100%;
  }
  &__headerTop {
    display: flex;
    gap: 2px;
    margin-bottom: 0;
    @media screen and (max-width: 1024px) {
      margin-bottom: 6px;
      gap: 8px;
    }
  }
  &__content {
    flex-shrink: 0;
    margin-top: 0;
    max-height: 100%;
    overflow: auto;
  }
  &__ctaBadge {
    height: 26px;
    background-color: theme.$red-light-01;
    position: absolute;
    bottom: -17px;
    padding: 8px 10px;
    left: 0px;
    display: flex;
    width: 100%;
    &_ctaText {
      font-size: 11px;
      color: theme.$red;
      pointer-events: none;
    }
  }
  &__cta {
    font-size: 10px;
  }
  &__planInfo {
    display: flex;
    align-items: center;
    gap: 8px;
    button {
      font-size: 12px;
      padding: 8px;
    }
    @media screen and (max-width: 1024px) {
      justify-content: space-between;
      position: relative;
      top: -14px;
    }
  }
  &__featuresTableWrap {
    padding-left: 8px !important;
  }
  &__features {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    padding-left: 18px;
    margin-top: 24px;
    gap: 14px;
    padding-right: 8px;
    &_list {
      display: flex;
      gap: 8px;
      svg {
        fill: theme.$orange3;
        min-width: 16px;
      }
    }
  }
  &__footer {
    display: flex;
    bottom: 37px;
    left: 30px;
    flex-shrink: 0;
    margin-top: auto;
    padding-left: 30px;
    padding-right: 18px;
    padding-bottom: 28px;
    gap: 8px;
    justify-content: space-between;
    @media screen and (max-width: 1024px) {
      background-color: theme.$blue;
      position: static;
      bottom: 0;
      left: 0;
      justify-content: center;
      width: 100%;
      border-radius: 0px 0px 6px 6px;
      margin-top: 10px;
      padding: 10px;
      button {
        color: theme.$white !important;
        width: 100%;
        svg {
          fill: theme.$white !important;
        }
      }
    }
  }
}
.subActionCta {
  margin: 18px;
}

.badge {
  display: flex;
  padding: 3px 7px;
  align-items: flex-start;
  border-radius: 28px;
  background-color: theme.$orange3;
  justify-content: center;
  &_text {
    font-size: 10px;
    color: theme.$white;
  }
}

.planCard__featuresTable {
  width: 100%;
  border-collapse: collapse;
}

.planCard__featuresTable th,
.planCard__featuresTable td {
  border: 1px solid theme.$borderGrey;
  padding: 1px;
  text-align: left;
  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 240px;
    @media screen and (min-width: 601px) {
      max-width: 140px;
    }
  }
  svg {
    fill: theme.$orange3;
    min-width: 16px;
  }
}

.planCard__featuresTable {
  thead {
    th {
      color: theme.$orange3;
      font-size: 10px;
      text-align: center;
    }
  }
}

.label {
  margin-left: 4px;
}
