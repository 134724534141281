@import 'styles/theme/theme';

.arrowAnchor {
  position: relative;
  max-width: 100%;
}

.scrollContainer {
  display: flex;
  align-items: center;
  overflow-x: auto;
  white-space: nowrap;
  // hide scrollbar
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  > * {
    scroll-snap-align: start;
  }
}

.scrollIndicator {
  position: absolute;
  border: none;
  background: var(--bgColor, $backgroundGrey);
  height: 100%;
  top: 0;
  padding: 4px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.scrollIndicatorLeft {
  left: 0;
  border-right: 1px solid $secondaryGrey;
  padding-left: 0;
}

.scrollIndicatorRight {
  right: 0;
  border-left: 1px solid $secondaryGrey;
  padding-right: 0;
}

.indicatorVisible {
  opacity: 1;
  visibility: visible;
}
