@use 'styles/theme/theme' as theme;

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 8px;
  margin-bottom: 12px;
  &__actions {
    display: inline-flex;
    align-items: center;
    &_item {
      display: inline-flex;
      & + & {
        margin-left: 8px;
        margin-top: -2px;
        display: flex;
        &::before {
          content: '';
          width: 1px;
          height: 18px;
          background-color: theme.$tertiaryGrey;
          margin-right: 8px;
        }
      }
    }
  }
  &__hasDescription {
    margin-bottom: 0;
  }
  border-bottom: 1px solid theme.$borderGrey;
  padding-bottom: 12px;

  &__hideSectionDivider {
    border-bottom: none;
    padding-bottom: 0px;
  }
}

.linkIcon {
  display: none;
  margin-top: 2px;

  @media screen and (max-width: 600px) {
    display: inline-block;
    fill: theme.$secondaryGrey !important;
  }
}

.editBtn {
  min-width: 69px;
  height: fit-content;
  color: theme.$blue;
  &__icon {
    fill: theme.$blue !important;
  }
  &:hover {
    color: theme.$blue;
  }
}

.sectionBlock {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__dotted {
    padding: 16px 8px;
    position: relative;
    &_background {
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23757171' stroke-width='2' stroke-dasharray='4' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e");
      border-radius: 4px;
      background-color: rgba(197, 197, 197, 0.2);
      margin: -16px -8px;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  .seeAllButton {
    margin-top: 20px;
    margin-right: auto;
  }
}

.leftBoxIcon {
  display: inline-flex;
  flex: 1;
  @media only screen and (max-width: 600px) {
    display: flex;
    align-items: flex-start;
    &::before {
      display: block;
      content: '';
      width: 10px;
      height: 10px;
      margin-top: 4px;
      margin-left: -16px;
      background-color: var(--current-theme-primary-text-color);
      margin-right: 6px;
    }
  }
}

:global(.theme-dark) {
  .leftBoxIcon {
    &::before {
      display: none;
    }
  }
}

.seeAllLink {
  display: flex;
  font-size: 12px;
  align-self: center;
  margin-left: auto;
  color: var(--current-theme-cta-text-color);
  & > * {
    color: var(--current-theme-cta-text-color);
  }
  svg {
    fill: var(--current-theme-cta-text-color) !important;
  }
  margin-top: 2px;
}

.seeAllArrow {
  svg {
    fill: theme.$blue;
  }
}

.addBtn {
  margin-left: 16px;
  color: theme.$blue;
  &__icon {
    fill: theme.$blue !important;
  }
  &:hover {
    color: theme.$blue;
  }
}

.title {
  display: block;
  text-align: left;
  white-space: pre-line;
  cursor: unset;
  color: var(--current-theme-section-header-text-color);

  &:hover {
    .linkIcon {
      display: inline-block;
      fill: var(--current-theme-icon-primary-color) !important;
      margin-top: 0;
    }
  }

  & > svg {
    vertical-align: middle;
  }

  &__isLink {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      color: var(--current-theme-section-header-text-color) !important;
      & > * {
        display: inline;
        color: var(--current-theme-cta-text-color);
      }
      svg {
        fill: var(--current-theme-cta-text-color);
      }
    }
  }
}

.count {
  margin-top: 2px;
  &:before {
    content: ' •';
    margin-left: 5px;
  }
}

.description {
  width: 100%;
  margin-top: 8px;
  margin-bottom: 12px;
}

.content {
  padding: 12px 16px;
  border-radius: 8px;
  background: var(--current-theme-card-background-color);
  border: 1px solid var(--current-theme-card-border-color);
}
