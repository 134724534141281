@use 'styles/theme/theme' as *;

.badge {
  display: flex;
  padding: 3px 7px;
  align-items: flex-start;
  border-radius: 32px;
  background-color: $orange3;
  justify-content: center;
  margin-top: 10px;
  &_text {
    font-size: 10px;
    color: $white;
  }
}

.pricingTable {
  border-radius: 8px;
  border-left: 0;
  border-bottom: 0px;
}

.tableHeader {
  &__plan {
    padding: 15px;
    &_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 6px;
      &_label {
        white-space: break-spaces;
      }
    }
  }

  &__feature {
    padding: 15px;
    & > span {
      text-align: left;
      display: flex;
    }
  }
}

.bodyFeatureCell {
  padding: 15px;
  text-align: center;
  padding: 15px;
  text-align: left;
  border-right: 0px;
  @media screen and (max-width: 1024px) {
    white-space: nowrap;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
  }
}

.bodyPlanCell {
  padding: 15px;
  text-align: center;
  @media screen and (max-width: 1024px) {
    word-wrap: break-word;
    white-space: normal;
    max-width: 100px;
  }
}

@media screen and (max-width: 600px) {
  .pricingTable {
    order: 3;
    margin-top: 16px;
  }

  .tableHeader {
    &__plan {
      &_container {
        width: 100%;
      }
    }
  }

  .continueBtn {
    display: none;
  }
}

.pricingTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid $redLight;
  border-radius: 8px;
  overflow: hidden;

  th,
  td {
    border: 1px solid $redLight;
    padding: 2px 2px 2px 4px;
  }

  thead tr:first-child th:first-child {
    border-top-left-radius: 8px;
  }

  thead tr:first-child th:last-child {
    border-top-right-radius: 8px;
  }

  tbody tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
  }

  tbody tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }
}

.tableHeader__plan_label {
  word-wrap: break-word;
  text-align: center;
  max-width: 80px;
}
