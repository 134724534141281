@use 'styles/theme/theme' as theme;

.wrapper {
  position: relative;
}

.root {
  position: sticky;
  top: -1px;
  width: 100%;
  background-color: theme.$white;
  padding-top: 1px;

  &.sticky {
    z-index: 5;
  }
}

.isHidden {
  display: none;
}

.isHeader {
  z-index: 120;
}

.border {
  position: absolute;
  bottom: -1px;
  width: 100vw;
  margin-left: 50%;
  left: -50vw;
  border-bottom: 1px solid transparent;
  &__isVisible {
    border-bottom: 1px solid theme.$tertiaryGrey;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  }
}

@media screen and (max-width: 600px) {
 .isHeader {
  padding: 0 16px;
  background: linear-gradient(180deg, #FFF -21.86%, #FFF 68.51%);
  box-shadow: -2px 7px 11px -6px rgba(0, 0, 0, 0.3);
 }
}
