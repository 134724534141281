@use 'styles/theme/theme' as theme;

.organizationCard {
  display: flex;
  padding: 12px 14px;
  border-radius: 6px;
  border: 1px solid theme.$borderGrey;
  background: theme.$white;
  gap: 8px;
  justify-content: space-between;
  &__arrowRight {
    margin: auto 0;
    svg {
      fill: theme.$tertiaryGrey;
    }
  }
  cursor: pointer;
}

.newProfessionalEmail {
  padding-left: 24px;
  padding-right: 24px;
}

.whitelistedSelectedOrg {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  background: hsla(0, 0%, 98%, 1);
  margin-left: -24px;
  margin-right: -24px;
}
.whiteListedOrgList {
  display: flex;
  flex-direction: column;
  &__title {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
  &__cardsWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 170px;
    overflow: auto;
    margin-bottom: 20px;
  }
}

.getJobInfo {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 20px;
  &__form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &__formAction {
    margin-bottom: 20px;
    button {
      width: 100%;
    }
  }
}

.form__select {
  width: 100%;
}

.preferenceSelection {
  &_wrapper {
    display: flex;
    flex-direction: column;
  }
  &__title {
    margin-top: 20px;
    margin-bottom: 27px;
    text-align: center;
  }
  &__checklist {
    padding: 10px 12px;
    border-radius: 2px;
    border: 1px solid theme.$borderGrey;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    &_checkbox {
      padding: 0px;
      display: flex;
      align-items: flex-start;

      :global {
        .MuiCheckbox-colorPrimary {
          padding: 0px;
          padding-left: 8px;
          padding-right: 8px;
        }
        .MuiFormControlLabel-label {
          font-size: theme.$font-size-14;
          font-weight: theme.$font-weight-regular;
          color: theme.$primaryGrey;
          &:hover {
            font-weight: theme.$font-weight-regular;
          }
        }
      }
    }
  }
  &__formAction {
    margin-bottom: 20px;
    margin-top: 28px;
    button {
      width: 100%;
    }
  }
}

.disabledCards {
  opacity: 0.5;
  pointer-events: none;
}
