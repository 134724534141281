@import 'styles/breakpoints';
@import 'styles/mixins';
@import 'styles/theme/theme';

.autocomplete {
  width: 100%;
  max-width: 340px;
  @media (max-width: 1024px) {
    max-width: 100%;
  }
  padding-bottom: 15px;
  & > div {
    border-radius: 8px;
    & > div {
      border-radius: 8px;
      padding-right: 0px !important;
      & input {
        border-radius: 8px;
        font-size: 16px;
        max-width: calc(100% - 45px) !important;
      }
    }
  }
  :global {
    & .MuiInputBase-root.Mui-focused fieldset {
      border-color: #0067c5 !important;
    }
    & label.Mui-focused {
      color: #0067c5;
    }
  }
}

.fullWidth {
  max-width: 100%;
}

.popover {
  z-index: 100001;
}

.noResult {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  &__searchIcon {
    color: $primaryGrey;
  }
  &__title {
    margin: 8px 0px;
  }
  &__info {
    line-height: 16px;
    text-align: center;
  }
}

.addNewEntity {
  display: flex;
  padding: 10px;
  grid-gap: 12px;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px 0px 8px 8px;
  background: $whiteSmoke;
}
