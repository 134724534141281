@use 'styles/theme/theme' as theme;

.profileClaim {
  padding: 0 24px;
  margin-bottom: 20px;
  &__header {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &__options {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
}
