@use 'styles/theme/theme';

.modalContainer {
  width: 100% !important;
  position: static !important;
  @media screen and (max-width: 1024px) {
    width: 100% !important;
  }
  top: 50%;
  position: relative !important;
}

.drawerRoot {
  max-height: 90%;
  z-index: 1201;
  @media screen and (max-width: 1024px) {
    max-height: 100%;
  }
}
.removeDrawerRootcss {
  position: static !important;
}

.disabled {
  pointer-events: none;
}
.paywallModalHeader {
  width: 100%;
  height: 58px;
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: theme.$orangeLight;
  border-top: 1px solid theme.$primaryGrey;
  align-items: center;
  cursor: pointer;

  &__hideContent {
    margin-bottom: 0;
    border-top: 1px solid theme.$primaryGrey;
    &:hover {
      border-top: 1px solid theme.$blue;
    }
    &__hasProfileAccess {
      display: none !important;
    }
  }
  &__leftSection {
    @media screen and (max-width: 800px) {
      display: none;
    }
  }

  &__rightSection {
    display: flex;
    gap: 16px;

    &_title {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
    }
    &_actions {
      display: flex;
      gap: 16px;
      margin-right: 10px;
      @media screen and (max-width: 1024px) {
        align-items: center;
        gap: 0;
      }
      &_titleLogin {
        font-weight: 700;
        @media screen and (max-width: 1024px) {
          text-decoration: underline;
          font-size: 12px;
        }
        &:hover {
          text-decoration: underline;
          .paywallModalHeader__rightSection_actions_titleLogin_arrowRightIconNone {
            opacity: inherit;
          }
        }
        &_arrowRightIconNone {
          opacity: 0;
          color: theme.$blue;
          fill: theme.$blue;
          margin-left: 5px;
          @media screen and (max-width: 1024px) {
            opacity: 1;
          }
        }
      }
    }
  }
  &__wrapper {
    max-width: 1275px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    &__loggedIn {
      flex-direction: row;
    }
    @media screen and (min-width: 601px) and (max-width: 1024px) {
      justify-content: center;
    }

    @media screen and (max-width: 1024px) {
      justify-content: end;
    }
  }
  &_closeBtn {
    cursor: pointer;
  }
  @media screen and (max-width: 1024px) {
    margin-bottom: 0;
    padding: 16px 12px;
    position: sticky;
    top: 0;
    background-color: theme.$orangeLight;
    z-index: 1;
  }
}

.paywall__modal {
  &__ignore {
    display: none;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 1280px;
    padding: 15px;

    @media screen and (min-width: 1025px) {
      padding-left: 0;
    }
    // For mobile screens
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
      padding-left: 15px;
    }

    // For tablet screens
    @media screen and (min-width: 601px) and (max-width: 1024px) {
      flex-direction: row;
      &__content {
        width: 70%;
      }
      &__nudges {
        width: 25%;
      }
    }
    &__hardWall {
      flex-direction: row-reverse;
      @media screen and (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    margin-left: 3px;
    // For mobile screens
    @media screen and (max-width: 1024px) {
      width: 100%;
      margin-left: 0;
      justify-content: center;
    }

    // For tablet screens
    @media screen and (min-width: 601px) and (max-width: 1024px) {
      width: 60%;
    }
  }

  &__nudges {
    width: 28%;

    // For mobile screens
    @media screen and (max-width: 1024px) {
      width: 100%;
    }

    // For tablet screens
    @media screen and (min-width: 601px) and (max-width: 1024px) {
      width: 35%;
    }
  }
}

.backdrop {
  display: none;
}

.drawerPaper {
  box-shadow: none;
  max-height: 100%;
  border-radius: 0;
}
.withSlideStyle {
  min-height: 58px;
  &__noProfile {
    display: none;
  }
}

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: theme.$backgroundGrey2;
  padding: 15px;
  flex-direction: column;
  bottom: 0;
  .title {
    @media screen and (max-width: 1024px) {
      margin-bottom: 8px;
    }
  }
  &__customerLogo {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border: 1px solid theme.$borderGrey;
      padding: 8px;
    }
  }
  @media screen and (max-width: 1024px) {
    margin-bottom: 110px;
  }
  &__fluid {
    width: 100%;
    max-width: 1280px;
  }
}

.listWorks {
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  position: relative;
  li {
    list-style: none;
    margin-right: 10px;
    position: relative;
    padding-left: 15px;
    text-transform: uppercase;
    font-weight: 500;
    &:first-child {
      padding-left: 0px;
      @media screen and (max-width: 1280px) {
        padding-left: 15px;
      }
    }
    &:not(:first-child)::before {
      content: '•';
      position: absolute;
      color: theme.$orange;
      left: 0;
      top: 50%;
      bottom: -3px;
      transform: translateY(-50%);
      font-size: 12px;
    }
  }
}

.logoIcon {
  width: 17px;
}

.buttonArrowDown {
  width: 34px;
  min-width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid theme.$blue;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  cursor: pointer;

  background-color: theme.$borderGrey;
  svg {
    transform: rotate(90deg);
    height: 18px;
    width: 18px;
    color: theme.$blue;
    fill: theme.$blue;
  }
}
.buttonArrowDown {
  transition: transform 0.5s ease-in-out;
}

.btnCta__close {
  transform: rotate(180deg);
}

.scrollLeftIndicator,
.scrollRightIndicator {
  border-right: transparent;
  cursor: pointer;
  height: 58px;
  border: 1px solid theme.$borderGrey;
  background: linear-gradient(90deg, #fff -21.86%, #fff 68.51%);
  z-index: 1;

  @media screen and (max-width: 1024px) {
    display: none !important;
  }
}

.scrollLeftIndicator {
  box-shadow: 10px 0px 8px -6px rgba(0, 0, 0, 0.3);
  border-right: transparent;

  svg {
    margin-left: 7px;
  }
}

.scrollRightIndicator {
  border-left: transparent;
  box-shadow: -13px 0px 8px -6px rgba(0, 0, 0, 0.3);
  svg {
    margin-right: 7px;
  }
}

.testimonials {
  max-width: 1200px;
  display: flex;
  width: 90%;
  margin: 0 auto;
  gap: 17px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  @media screen and (max-width: 1024px) {
    width: 100%;
    margin-top: 8px;
  }
  &__title {
    justify-content: start;
  }
  .leftSection {
    flex: 2.5;
    min-width: 25%;
    max-width: 25%;
  }

  // Right section (70%)
  .rightSection {
    flex: 7.5;
    min-width: 75%;
    max-width: 75%;
    @media screen and (max-width: 1024px) {
      flex: 10;
      min-width: 100%;
      max-width: 100%;
    }
  }
  .testimonialTitle {
    font-size: 11px;
    font-weight: 400;
    @media screen and (max-width: 1024px) {
      text-align: center;
    }
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
  &__list {
    @media screen and (max-width: 1024px) {
      width: 100%;
      flex-direction: column !important;
      gap: 8px !important;
      & > div {
        width: 100% !important;
        margin-bottom: 0px;
      }
    }
  }
  &__list__wrapper {
    width: 300px;
    min-width: 300px;
  }
}

.paywall__modal__wrapper {
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
  transform: translateY(0);
  opacity: 1;
  display: flex;
  justify-content: center;

  &.slideDown {
    transform: translateY(100%);
    opacity: 0;
  }
}

.paywall__main {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.4s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.4s ease-out;
  display: block;
  pointer-events: none;
  &__header {
    max-width: 1280px;
    text-align: right;
    margin-top: 5px;
    margin-right: 10px;
    position: absolute;
    right: 0;
    z-index: 1200;
  }
}

.paywall__main__show {
  max-height: 100vh;
  opacity: 1;
  transition: max-height 0.4s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.4s ease-in;
  overflow: visible;
  pointer-events: auto;
}

.loggedInPaywallWrapper {
  &__nonLoggedIn {
    background-color: theme.$white;
  }
  background-color: theme.$orangeLight;
  .paywallModalHeader {
    &__leftSection {
      display: flex;
      &_logoTitle {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }
    &__rightSection {
      align-items: center;
      &_actionTitle {
        color: theme.$blue;
      }
      &_actions {
        align-items: center;
        gap: 8px;
      }
    }
  }
}

.paywallModalHeader__hideContent_mobile {
  display: none;
}

.paywall__modal__content {
}
