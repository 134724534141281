@use 'styles/theme/theme' as *;

.root {
  background-color: $orangeLightBackground;
  border: 1px solid $borderGrey;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  &.onlyBenefits {
    width: 100%;
    align-items: center;
    border: 0;
    padding-bottom: 30px;
  }
}

.paidPlanBenefits {
  max-width: 1280px;
  padding: 24px 24px 24px 35px;
  &__payButton {
    text-align: right;
    margin-bottom: 5px;
    margin-top: 5px;
    button {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 600px) {
    padding: 16px;
  }
}

.testimonialsSection {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.scrollLeftIndicator {
  box-shadow: $boxShadow;
  border-right: transparent;

  svg {
    margin-left: 7px;
  }
}

.badge {
  display: flex;
  padding: 3px 7px;
  align-items: flex-start;
  border-radius: 32px;
  background-color: $orange3;
  justify-content: center;
  margin-top: 10px;
  &_text {
    font-size: 10px;
    color: $white;
  }
}

.footer {
  margin-left: 45px;
  display: flex;
  flex-direction: column;
  row-gap: 38px;
  margin-bottom: 38px;
}

.scrollRightIndicator {
  border-left: transparent;
  box-shadow: $boxShadow1;
  svg {
    margin-right: 7px;
  }
}

.platformHeader {
  display: flex;
  width: 100%;
  padding: 20px 17px 20px 35px;
  max-width: 1280px;
  border-bottom: 1px solid;
  .platformDetails {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.logoContainer {
  display: flex;
  align-items: flex-end;
  column-gap: 10px;
  .logoImage {
    width: 160px;
    height: 16px;
  }
  .logoSubtitle {
    line-height: 0.85;
  }
}

.stats {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  column-gap: 3px;
}

.itemsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 11px !important;
  margin-top: 7px;

  .smallPoints {
    font-family: $secondary-font-family;
    font-size: 11px;
    font-weight: 400;
    line-height: 11px;
    color: $primaryGrey;
  }

  .separator {
    width: 1px;
    align-self: stretch;
    margin: 2px 0;
    background-color: $blackT10;
  }
}

.currentPlanNudge {
  display: flex;
  padding: 3px 7px;
  align-items: flex-start;
  border-radius: 32px;
  background-color: $borderGrey;
  justify-content: center;
  &_text {
    font-size: 10px;
  }
}

.description {
  &__benefits {
    margin-bottom: 0;
    padding-left: 20px;
    & > li {
      &:is(:first-child) {
        margin-top: 0;
      }
      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }
}

.pricingTable {
  border-radius: 8px;
  border: 1px solid $redLight;
  border-left: 0;
  border-bottom: 0px;
  margin: 32px 17px 0 35px;
}

.tableHeader {
  & > :nth-child(2) {
    border-left: 1px solid $redLight;
    border-right: 1px solid $redLight;
  }
  &__plan {
    width: 219px;
    border-bottom: 1px solid $redLight;
    padding: 15px;
    &_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 6px;
      .continueBtn {
        max-width: 100px;
      }
    }
  }

  &__feature {
    width: 368px;
    padding: 15px;
    border-left: 1px solid $redLight;
    border-bottom: 1px solid $redLight;
    & > span {
      text-align: left;
      width: 100%;
      display: flex;
    }
  }
}

.tableCell {
  padding: 15px;
  text-align: center;
  border-bottom: 1px solid $redLight;
  &:nth-child(2) {
    border-left: 1px solid $redLight;
    border-right: 1px solid $redLight;
  }

  &__feature {
    padding: 15px;
    text-align: left;
    border-left: 1px solid $redLight;
    border-right: 0px;
  }
}

.tableBody {
  & > :nth-last-child(2) {
    & > :first-child {
      border-bottom-left-radius: 8px;
    }
  }
  & > :last-child {
    & > :first-child {
      border-right: 0;
    }
    & > :nth-child(2) {
      border-left: 1px solid $redLight;
      border-bottom-left-radius: 8px;
    }
  }
}

.trustedBy {
  margin-bottom: 10px;
}

.viewCustomerListLink {
  margin-left: 5px;
  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
}

.selectPlan {
  display: none;
}

.profileHeader {
  display: none;
}

.entitySuggestions {
  margin-left: 0px;
  margin-right: 0px;
}

@media screen and (max-width: 600px) {
  .root .entitySuggestions {
    &__sectionBlock {
      padding: 16px 0px !important;
    }
  }

  .drawer {
    max-height: 100%;
  }

  .profileHeader {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    border-bottom: 1px solid $tertiaryGrey;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: $orangeLightBackground;
  }

  .description {
    order: 2;
    border-bottom: 1px solid $tertiaryGrey;
    padding-bottom: 5px;
  }

  .pricingTable {
    order: 3;
    margin: 24px 16px 0 16px;
  }

  .tableHeader {
    &__plan {
      width: 80px;
      &_container {
        width: 100%;
      }
    }
    &__feature {
      width: 182px;
    }
  }

  .continueBtn {
    display: none;
  }

  .tableFooterActions {
    display: none;
  }

  .platformHeader {
    order: 4;
    flex-direction: column;
    row-gap: 31px;
    background-color: $white;
    border-bottom: 0;
    margin-top: 21px;
    .platformDetails {
      align-items: center;
    }
    .stats {
      justify-content: center;
    }
  }

  .testimonialsSection {
    align-items: center;
  }

  .trustedBy,
  .artistProfile {
    display: block;
    text-align: center;
    width: 100%;
  }

  .footer {
    order: 5;
    margin: 28px 16px 16px 16px;
  }

  .selectPlan {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 16px;
    background-color: $white;
    row-gap: 10px;
    border-top: 1px solid $primaryGrey;
    box-shadow: 0px -4px 4px 0px hsla(0, 0%, 0%, 0.15);
    &__label {
      text-align: center;
      &_headline {
        white-space: pre-line;
        text-align: left;
        margin-left: 5px;
      }
    }
    &__actions {
      width: 100%;
      display: flex;
      column-gap: 16px;
      & > * {
        width: 100%;
      }
      &_paidPlan {
        padding-left: 31px;
        padding-right: 31px;
      }
    }
  }
}

.titleWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}
