.wrapper {
  position: relative;
}

.label {
  position: absolute;
  top: calc(50% + 20px);
  height: calc(50% - 20px);
  width: 100%;
  text-align: center;
  color: #af3227; // GOOGLE_MAPS_LABEL_COLOR
  font-weight: bold;
  overflow-wrap: break-word;
  white-space: normal;
}
