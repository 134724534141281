@use 'styles/theme/theme' as theme;

.bullet + .bullet {
  &::before {
    content: ' • ';
    font-style: normal;
  }
}
.forwardSlash + .forwardSlash {
  &::before {
    content: ' / ';
    font-style: normal;
  }
}
.comma + .comma {
  &::before {
    content: ', ';
    font-style: normal;
  }
}
