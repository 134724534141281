@import 'styles/theme/theme';

.login {
  position: relative;
  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 16px;
    position: relative;
    border-bottom: 1px solid $borderGrey;
    gap: 6px;
    position: sticky;
    top: 0;
    border-radius: 8px 8px 0 0;
    background: $white;
    z-index: 9;
    &_title {
      color: #666666;
    }
    &__image {
      display: flex;
      gap: 4px;
      align-items: flex-start;
    }
  }

  &__closeButton {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #ece9e9;

    &_noBorder {
      border-bottom: none;
    }
  }

  &__backButton {
    rotate: 180deg;
    cursor: pointer;
    position: absolute;
    left: 16px;
    top: 16px;
  }

  &__closeButton {
    cursor: pointer;
  }

  &__headerContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    &_titleWrap {
      margin-top: 16px;
      margin-bottom: 16px;
      display: flex;
    }
    &_title {
      margin-left: 8px;
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    text-align: center;
    &_text {
      color: $tertiaryGrey;
    }
  }
}

.listWorks {
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  position: relative;
  li {
    list-style: none;
    margin-right: 3px;
    position: relative;
    padding-left: 6px;

    &:not(:first-child)::before {
      content: '•';
      position: absolute;
      left: 0;
      top: 50%;
      bottom: -5px;
      transform: translateY(-50%);
      font-size: 12px;
      color: $secondaryGrey;
    }
  }
}

.page {
  &__backBtn {
    margin-top: 20px;
    @media screen and (min-width: 600px) {
      position: absolute;
      left: 65px;
    }

    cursor: pointer;
    &_icon {
      rotate: 180deg;
      cursor: pointer;
    }
  }
}

.loginForPage {
  position: static;
}

.loaderStyle {
  padding: 30px;
}
