@use 'styles/theme/theme' as theme;

.card {
  display: flex;
  align-items: center;
  @media screen and (max-width: 600px) {
    align-items: flex-start;
  }
  &_name {
    line-height: 0;
  }
  .details {
    padding: 0 10px;
    width: calc(100% - 34px);
  }
  .mediaHidden {
    width: 100%;
    padding: 0 10px 0 0;
  }
  .image {
    width: 34px;
    height: 47px;
    overflow: hidden;
    border: 1px solid theme.$borderGrey;
    background: theme.$whiteSmoke;
  }
  &_link {
    max-width: 100%;
    overflow: hidden;
    &:hover {
      .card_title {
        color: theme.$blue;
        text-decoration: underline;
      }
    }
  }
  &_fullWidth {
    max-width: 100%;
    overflow: hidden;
  }
  &__withCloseIcon {
    width: 100%;
    background: theme.$backgroundGrey2;
    border-radius: 2px;
    padding: 12px;
    & + & {
      margin-top: 8px;
    }
  }
}

.large {
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  .card {
    .details {
      padding: 0 10px;
      width: calc(100% - 38px);
    }
    .image {
      width: 38px;
      height: 52px;
      border: 1px solid theme.$borderGrey;
      background: theme.$whiteSmoke;
    }
    &_title {
      @media screen and (max-width: 600px) {
        font-size: 18px;
      }
    }
    &_stickyTitle {
      font-size: 24px;
      span {
        font-size: 24px;
      }
    }
    &_subtext,
    &_label {
      &__sticky {
        font-size: 12px;
      }
      @media screen and (max-width: 600px) {
        font-size: 12px;
      }
    }
  }
}

.iconOpenNewTab {
  margin-top: -2px;
  margin-left: 6px;
}
