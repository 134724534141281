@use 'styles/theme/theme' as theme;

.parent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.searchIcon {
  margin-bottom: 15px;
  fill: theme.$tertiaryGrey;
}