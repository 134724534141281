
@use 'styles/theme/theme' as theme;

.searchInput {
  position: relative;

  &__field {
    & > div {
      border-radius: 30px;
      background: theme.$white;
    }
    input {
      font-size: 12px;
      padding-right: 32px;
    }
  }

  &__icon {
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    height: 18px;
    width: 18px;
    margin: auto;
  }
  &__close_icon {
    position: absolute;
    top: 0;
    right: 36px;
    bottom: 0;
    height: 18px;
    width: 18px;
    margin: auto;
  }

  &__pipe_icon {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    color: theme.$tertiaryGrey;
  }
}