@use 'styles/theme/theme' as theme;

.root {
  display: block;
  position: relative;
}

.footer {
  position: absolute;
  bottom: 0;
  background: rgba(theme.$black, 0.7);
  padding: 4px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  * {
    color: theme.$white !important;
  }
}

.newWindowIcon {
  fill: theme.$white;
}

.mediaCard {
  position: relative;
}