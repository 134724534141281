@use 'styles/theme/theme' as theme;

.filterOptions {
  display: flex;
  flex-direction: column;
}

.filterDropdown {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__wrapper {
    position: relative;
    height: calc(100% - 52px);
  }
  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    &.blur {
      filter: blur(5px);
      pointer-events: none;
    }
  }
  &__title {
    padding: 16px;
    background-color: theme.$orangeLight;

    @media only screen and (max-width: 1024px) {
      padding: 20px 16px 6px 16px;
      background-color: theme.$white;
    }
  }
  &__header {
    display: flex;
    flex-direction: column-reverse;
    &_popover {
      flex-direction: column;
    }
    &_input {
      width: 100%;
      padding: 8px 12px 8px 10px;
    }
  }
  &__options {
    flex-grow: 1;
    padding: 4px 20px 16px 20px;
    overflow: auto;
    &_maxCountOptions {
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px solid theme.$borderGrey;
      .topResultText {
        display: block;
        margin-bottom: 8px;
      }
    }
  }

  .allResultsText {
    margin: 12px 0 4px 0;
    display: block;
  }

  &__footer {
    border-top: 1px solid theme.$borderGrey;
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    .clearSearch {
      padding: 8px 12px;
      color: theme.$secondaryGrey;
      &:hover {
        background-color: theme.$whiteSmoke;
      }
      &__disabled {
        opacity: 0.3;
      }
    }
    &_icon {
      svg {
        fill: theme.$secondaryGrey !important;
      }
    }
    &_iconActive {
      svg {
        fill: theme.$secondaryGrey !important;
      }
    }

    .submitSearch {
      &:hover {
        box-shadow: none;
      }
      &__disabled {
        opacity: 0.8;
      }
    }

    @media screen and (max-width: 1024px) {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      padding: 8px 16px;
      background-color: theme.$white;
      z-index: 2;
      gap: 9px;
      .clearSearch {
        padding: 8px 12px;
      }

    .submitSearch {
      flex-grow: 1;
    }
    }
    @media screen and (max-width: 600px) {
      padding-bottom: 42px;
    }
  }

  &.filterDropdownInline {
    .filterDropdown__title {
      background-color: transparent;
    }
    .filterDropdown__footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      padding: 8px 16px;
      background-color: theme.$white;
      z-index: 2;
      gap: 9px;
      .clearSearch {
        padding: 8px 12px;
      }

      .submitSearch {
        flex-grow: 1;
      }
    }
  }
}

.lockedFiltersModal {
  border-radius: 8px;
  border: 1px solid #FFBD99;
  background: linear-gradient(180deg, #FFFDFC 0%, #FFE5D7 100%);
  position: absolute;
  width: 260px;
  max-width: calc(100% - 30px);
  margin: auto;
  top: calc((50vh - 60px - 50px) / 2); // NOTE: 50vh modal height, 60px header height, 50px footer height
  right: 16px;

  @media screen and (max-width: 1024px) {
    width: 200px;
    top: calc((50vh - 20px - 30px) / 2);
  }

  @media screen and (max-width: 400px) {
    width: 160px;
  }

  &__content {
    padding: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    grid-gap: 8px;
    &_icon {
      display: flex;
      align-items: center;
      height: 26px;
      width: 26px;
      padding: 4px;
      border-radius: 50%;
      background-color: theme.$primaryGrey;
      svg {
        fill: theme.$white;
      }
    }
  }
  &__cta {
    &_primary {
      margin-top: 12px;
    }
  }
}