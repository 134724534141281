@import 'styles/theme/theme';

.avatar {
  border: 1px solid $secondaryGrey;
  display: flex;
  align-items: center;
  justify-content: center;

  &_circle {
    border-radius: 50%;
  }
  &_rect {
    border-radius: 0;
  }
}
.profileIcon{
  font-size: 14px;
}

.styleV4 {
  border: none;
  font-family: Roboto, serif;
  font-weight: 700;
  color: $tertiaryGrey;
  background-color: $whiteSmoke;
}