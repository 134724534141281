@use 'styles/theme/theme' as theme;

.root {
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid theme.$borderGrey;
  max-width: 180px;
  overflow: auto;
}

.typeList {
  &:not(.inline) {
    .chip {
      border-bottom: 1px solid theme.$borderGrey;
    }
  }
  & > div:last-child {
    .chip {
      border-bottom: none;
      font-size: 12px;
    }
  }
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid theme.$borderGrey;
  background-color: theme.$orangeLight;
  padding: 7px 12px;
  overflow: hidden;
  grid-gap: 8px;
  cursor: pointer;

  &__label {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    @media only screen and (max-width: 900px) {
      span {
        font-size: 12px;
      }
    }
  }
  &__icon {
    display: flex;
    align-items: center;
    height: 26px;
    width: 26px;
    padding: 4px;
    border-radius: 50%;
    background-color: theme.$primaryGrey;
    svg {
      fill: theme.$white;
    }
  }
}

.chip {
  background-color: theme.$orangeLight;
  padding: 12px 12px 12px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-left: 4px solid transparent;
  height: 44px;
  grid-gap: 4px;

  &:hover {
    border-left: 4px solid theme.$orange;
  }
  
  &.active {
    border-left: 4px solid theme.$orange;
    background-color: theme.$white;
  }

  &.disabled {
    color: theme.$tertiaryGrey;
    pointer-events: none;
    svg {
      fill: theme.$tertiaryGrey;
    }
  }

  &__label {
    flex-grow: 1;
  }
  &__applied {
    background-color: theme.$red;
    border-radius: 50%;
    padding: 2px;
    height: 20px;
    width: 20px;
    min-width: 20px;
    display: inline-block;
    text-align: center;
    line-height: 16px;
  }
  &__lock {
    width: 14px;
    min-width: 14px;
    fill: theme.$primaryGrey;
  }
  &__icon {
    width: 20px;
    min-width: 20px;
  }
}

.inline {
  display: flex;
  width: 100%;
  max-width: 100%;
  align-items: flex-start;
  position: relative;
  border: none;
  border-radius: 0;

  .inline {
    border-left: 1px solid theme.$borderGrey;
    flex-wrap: nowrap;
  }

  .header {
    border-bottom: none;
    border-radius: 28px;
    flex-shrink: 0;
    border: 1px solid theme.$borderGrey !important;
    background-color: theme.$white !important;
    padding: 8px 10px 8px 5px;
    height: 34px;
    margin-right: 6px;
    width: initial;

    &:hover {
      background: theme.$blueHover !important;
    }

    &__label {
      &_text {
        color: theme.$blue;
        padding: 4px 0px 4px 4px;
      }
    }

    &__icon {
      background-color: transparent;
      svg {
        fill: theme.$blue;
      }
    }
  }

  .chip {
    border-radius: 28px;
    padding: 12px;
    border: 1px solid theme.$borderGrey;
    white-space: nowrap;
    height: 34px;
    background: theme.$white;

    &_icon {
      margin-left: 4px;
    }
    
    &:hover {
      .chip_icon {
        fill: theme.$orange;
      }
    }
  
    &.active {
      background-color: theme.$orangeLight;
    }
  }

  .overflowItemWrapper {
    margin: 0 6px;
  }
}

.filterPopover {
  background-color: theme.$white;
  margin: 0 2px;
  border-radius: 18px;
  overflow: hidden;
  width: 270px;
  height: 499px;
}

.filterModal {
  max-width: 462px;

  @media screen and (max-width: 1024px) {
    height: 550px;
  }
  &__content {
    display: grid;
    grid-template-columns: 171px auto;
    height: 50vh;
    min-height: 450px;
    &_facets {
      background-color: theme.$orangeLight;
      padding-bottom: 52px;
    }
    &_options {
      overflow: auto;
    }
    @media only screen and (max-width: 900px) {
      height: 100%;
    }
  }

  .header {
    border-bottom: none;
    padding: 0;
    background-color: theme.$white;
  }

}

.filterDrawerPaper {
  height: 90%;
  max-height: none;
}

.filterDrawerContent {
  height: calc(100% - 60px);
}

.filterDrawer {
  @media only screen and (max-width: 1000px) {
    max-height: 90%;
  }
}

@media only screen and (max-width: 600px) {
  .closeIcon {
    display: none;
  }
  .inline {
    .header {
      flex-direction: row;
      padding: 8px 5px 8px 10px;
    }
  }
}
