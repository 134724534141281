@import 'styles/theme/theme';

.authSelector {
  padding: 0 16px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 600px) {
    padding: 0 16px 16px;
    &__withPaywall{
      padding: 0;
    }
  }

  &__header {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    &_title {
      max-width: 342px;
      text-align: center;
    }
    &_home {
      cursor: pointer;
    }
    &__title {
      margin-top: 16px;
      color: $primaryGrey;
    }
    &__bulletList {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin-top: 16px;
      color: $secondaryGrey;
      padding: 0;
      &__item {
        margin: 0 10px;
        list-style: disc;
        color: $orange;
        span {
          color: $secondaryGrey;
        }
        &:last-child {
          margin-right: 0;
        }
        &:first-child {
          list-style: none;
        }
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    &_separator {
      color: $secondaryGrey;
    }

    &__button {
      width: 100%;
      height: 42px;
      color: $primaryGrey !important;
      @media screen and (max-width: 600px) {
        font-size: 12px;
      }

      &:hover {
        color: $blue !important;
        border: 1px solid $blue;
      }

      svg {
        margin-right: 8px;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    &__text {
      margin-right: 4px;
    }
    &_linkButton {
      margin-left: 4px;
    }
  }
}

.orSeparator {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  hr {
    border: none;
    border-top: 1px solid $tertiaryGrey;
    width: 100%;
  }
}
