@import 'styles/theme/theme';

.checkbox {
  align-items: center;
  margin-right: 0;
  padding-top: 5px;
  :global {
    .MuiCheckbox-colorPrimary {
      color: $tertiaryGrey;
    }
    .MuiCheckbox-colorPrimary.Mui-checked {
      color: $blue;
    }
    .MuiFormControlLabel-label {
      font-size: $font-size-12;
      font-weight: $font-weight-regular;
      color: $primaryGrey;
      &:hover {
        font-weight: $font-weight-bold;
      }
    }
  }
}

.isSelected {
  :global {
    .MuiFormControlLabel-label {
      font-weight: $font-weight-bold;
    }
  }
} 
