@use 'styles/theme/theme' as theme;

.link {
  display: block;
  text-overflow: ellipsis;
  white-space: normal;
  &__variant {
    &_landscape {
      width: 100%;
    }
  }
}

.withoutLink {
  &:hover {
    text-decoration: none;
  }
}

.card {
  display: flex;
  &__variant {
    &_landscape {
      flex-direction: row;
      grid-gap: 10px;
      align-items: center;
      width: 100%;

      @media screen and (max-width: 1280px) {
        padding: 4px;
        border: 1px solid var(--current-theme-card-border-color);
        background: var(--current-theme-card-background-color);
        border-radius: 8px;
        &__chevron {
          display: none;
        }
      }

      @media screen and (max-width: 360px) {
        width: 290px;
      }

      &.card {
        &__size {
          &_small {
            height: 64px;

            @media (max-width: 1280px) {
              width: 240px;
              height: 74px;
            }

            @media (max-width: 768px) {
              width: 155px;
            }

            .card {
              &__img {
                width: 43.23px;
                height: 100%;
              }
            }
          }
          &_large {
            height: 161.32px;

            @media (max-width: 1280px) {
              height: 171.32px;
            }

            .card {
              &__img {
                width: 109px;
                height: 100%;
              }
            }
          }
        }
      }

      .card {
        &__textWrapper {
          flex-grow: 1;
        }
        &__img {
          border-radius: 6px;
        }
      }
    }
    &_portrait {
      flex-direction: column;
      border: 1px solid var(--current-theme-card-border-color);
      position: relative;
      box-sizing: border-box;

      &.card {
        &__size {
          &_small {
            width: 109px;
            height: 228px;

            .card {
              &__img {
                width: 100%;
                height: 152px;
              }
              &__chevron {
                top: 126px;
              }
            }
          }
          &_large {
            width: 149px;
            height: 267px;

            .card {
              &__img {
                width: 100%;
                height: 190px;
              }
              &__chevron {
                top: 160px;
              }
            }
          }
        }
      }

      .card {
        &__textWrapper {
          padding: 8px;
          background: var(--current-theme-card-background-color);
          width: 100%;
        }

        &__chevron {
          position: absolute;
          right: 8px;
          height: 24px;
          width: 24px;
          background: var(--current-theme-card-background-color);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  &__img {
    overflow: hidden;
    flex-shrink: 0;
    background-color: theme.$white;
  }

  &__textWrapper {
    display: flex;
    padding: 8px 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    overflow: hidden;

    .title {
      color: var(--current-theme-primary-text-color);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      word-wrap: break-word;
      text-align: left;
    }
  }

  &__chevron {
    height: 18px;
    width: 18px;
    visibility: hidden;
    svg {
      fill: var(--current-theme-icon-primary-color);
    }
  }

  &:hover {
    .card {
      &__textWrapper {
        .title {
          color: var(--current-theme-cta-text-color);
        }
      }
      &__chevron {
        visibility: visible;
      }
    }
  }
}
