@import 'styles/theme/theme';

.checkbox {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  padding: 8px;
  border-radius: 50%;
  border: 2px solid transparent;

  &:hover {
    background-color: $blueLight;
  }
  &:active {
    border: 2px solid $blue;
    transition: 0.15s all ease-out;
  }
  .checkboxInput {
    border: 0; 
    clip: rect(0 0 0 0); 
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .checkboxInput:checked + label > svg {
    height: 20px;
  }
  .checkboxInput:checked + label {
    background-color: $blue;
    color: $white;
    border-radius: 3px;
  }
  label {
    color: $blue;
    line-height: 40px;
    cursor: pointer;
    position: relative;
    width: 20px;
    &:after {
      content: "";
      height: 20px;
      width: 20px;
      float: left;
      border: 2px solid $blue;
      border-radius: 3px;
      transition: 0.15s all ease-out;
    }
  }
  label:active::after {
    background-color: $blue;
    border-radius: 3px;
  }
  svg {
    height: 0;
    width: 20px;
    position: absolute;
    left: 0px;
    top: 0px;
  }
}

.disabled {
  pointer-events: none;
  .checkboxInput {
    overflow: hidden;
    &:checked + label {
      background-color: $blue;
      opacity: 0.63;
    }
    &:checked + label {
      color: $white;
    }
  }
  label {
    &:after {
      content: "";
      height: 20px;
      width: 20px;
      float: left;
      border-radius: 3px;
      border: 2px solid $blue;
      transition: 0.15s all ease-out;      
    }
  }
}