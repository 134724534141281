@import 'styles/theme/theme';

.emailForm {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0 24px;
  position: relative;
  &__header {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    &_title {
      max-width: 342px;
      text-align: center;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    &_required {
      color: $red;
    }
  }

  .inputContainer {
    width: 100%;
    text-align: right;
    &_passwordIcon {
      cursor: pointer;
    }
    &__bottomWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 12px;
    }
    &__label {
      cursor: pointer;
    }
    &__checkboxWrap {
      display: flex;
      align-items: center;
      gap: 6px;
      &_checkbox {
        padding: 0;
      }
    }
  }

  .input {
    width: 100%;
  }

  :global(.MuiOutlinedInput-root) {
    border-radius: 4px;
    height: 42px;
    &:-webkit-autofill {
      height: 42px;
    }
  }

  :global {
    .Mui-disabled .MuiOutlinedInput-notchedOutline {
      border: 1px solid $tertiaryGrey;
      background: rgb(0 0 0 / 4%);
    }
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }

  .buttonContainer {
    width: 100%;
    margin-top: 40px;
    button {
      margin-top: 4px;
      margin-bottom: 20px;
      width: 100%;
    }
  }

  .loader {
    position: absolute;
    background: #ffffffd1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    z-index: 9;
  }
}

.emailLoginLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  position: relative;
  top: -34px;
  background-color: $white;
  padding: 12px 40px;
  text-align: center;
  &__title {
    margin-top: 12px;
  }
  &__description {
    margin: 12px 0;
    color: $secondaryGrey;
  }
  &__expireNotify {
    margin-top: 12px;
  }
  &__linkDesc {
    color: $secondaryGrey;
  }
  &__resend {
    margin: 12px 0;
  }
}

.loader {
  position: absolute;
  background: #ffffffd1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  z-index: 9;
  left: 0;
  right: 0;
}

.passCodeButtonContainer {
  margin-top: 40px;
  width: 100%;
  text-align: center;

  &_button {
    width: 100%;
    color: $primaryGrey;
    background-color: $backgroundGrey;
  }
}

.orSeparator {
  margin-top: 12px;
  margin-bottom: 12px;
}
