@use 'styles/theme/theme' as theme;

.inline {
  &_grid {
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    justify-content: center;
  }

  &_wrapper {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: 10px;

    @media screen and (max-width: 1280px) {
      gap: 0px;
      margin: 0 16px;
    }

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
      gap: 0px;
      margin: 0px;
    }
  }
  &_title {
    &:after {
      content: ': ';
    }
    @media (max-width: 600px) {
      display: none;
    }
  }
  &_card {
    max-width: 170px;
    width: 170px;
    background: theme.$white;
    padding: 4px;
    border: 1px solid theme.$borderGrey;
    overflow: visible;
    &_grid {
      max-width: 163px;
      width: 163px;
    }
  }
}

.leftBoxIcon {
  &::before {
    display: none !important;
  }
}

.horizontalScrollItems > div {
  display: flex;
  column-gap: 21px;
}

.artist,
.organization {
  width: 106px;
  height: 222px;
  border: 1px solid theme.$tertiaryGrey;
  & > span {
    display: block !important;
  }
}

.sectionBlock {
  overflow: hidden;
  .sectionBlockHeader {
    justify-content: flex-start;
    column-gap: 20px;
    padding-bottom: 8px;
    margin-bottom: 0px;
    &__leftBoxIcon {
      flex: none;
      border-right: 1px solid theme.$tertiaryGrey;
      padding-right: 20px;
    }
  }
}

.sectionBlock .premiumNudge {
  padding: 8px 0px 0px 0px;
  padding-left: 0;
  margin-top: 0;
}

.claimProfileCard {
  @media (max-width: 1280px) {
    display: none;
  }
}

.skeleton {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .sectionBlock {
    padding: 16px !important;
    width: 100% !important;
    margin: 0 !important;
  }

  .sectionBlockHeader {
    justify-content: space-between;
    &__leftBoxIcon {
      padding-right: 0;
      border-right: none;
    }
  }

  .horizontalScrollItems {
    width: calc(100% + 32px);
    max-width: calc(100% + 32px);
    margin-left: -16px;
    z-index: 2;
  }

  .sectionBlock .premiumNudge {
    margin-top: 8px;
    padding-bottom: 8px;
  }
}
