@use 'styles/theme/theme' as theme;
.claimCard {
  width: 100%;
  background: theme.$whiteSmoke;
  border: theme.$borderGrey;
  display: flex;
  border: 1px solid theme.$tertiaryGrey;

  &__iconWrapper {
    height: 30px;
    width: 30px;
    background: theme.$borderGrey;
    display: flex;
    align-content: center;
    justify-content: center;
    margin-right: 6px;
    border-radius: 2px;

    .icon {
      align-self: center;
    }
  }
  &__textWrapper {
    text-align: left;
  }
  &__textWrapperVertical {
    text-align: center;
    white-space: break-spaces;
  }
  &.claimCardVertical {
    display: flex;
    flex-direction: column;
    width: 109px;
    justify-content: center;
    align-items: center;
  }

  .link {
    padding: 4px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6px;
    white-space: unset;
    text-align: center;
    &:hover {
      text-decoration: unset;
      .claimProfile {
        text-decoration: underline;
      }
    }
  }
  .claimProfile {
    font-size: 12px;
    &__vertical {
      margin-top: 5px;
    }
  }
  .linkVertical {
    flex-direction: column;
    width: 109px;
  }
}
