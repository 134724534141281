@import 'styles/theme/theme';

.textField {
  :global {
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $blue;
    }
    .MuiInputLabel-root.Mui-focused {
      color: $blue;
    }
  }
}
