@use 'styles/theme/theme' as theme;

.actionCard {
  border-radius: theme.$border-radius-extra_small;
  border: 1px solid theme.$borderGrey;
  background: theme.$white;
  padding: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  &__icon {
    fill: theme.$tertiaryGrey;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
