@use 'styles/theme/theme' as theme;

.root {
  display: grid;
  grid-template-columns: 110px auto 80px;
  align-items: center;

  &.hideShowAll {
    grid-template-columns: 80px auto;
  }
  &.hideTitle {
    grid-template-columns: auto 80px;
  }
  &.showOnlyLogos {
    grid-template-columns: auto;
  }

  .logoList {
    height: 48px;
    overflow: hidden;
    gap: 3px;
    a {
      border: 1px solid theme.$borderGrey;
    }
  }
}

.showAll {
  display: flex;
  flex-direction: column;
  grid-gap: 36px 0;
  .logoList {
    height: auto;
    overflow: visible;
    grid-gap: 60px 0;

    &_item {
      padding: 0 20px;
      flex: 0 0 180px;

      @media screen and (min-width: 1201px) {
        &:nth-child(6n + 1) {
          &:before {
            display: none;
          }
        }
      }

      @media screen and (min-width: 1001px) and (max-width: 1200px) {
        &:nth-child(5n + 1) {
          &:before {
            display: none;
          }
        }
      }

      @media screen and (min-width: 801px) and (max-width: 1000px) {
        &:nth-child(4n + 1) {
          &:before {
            display: none;
          }
        }
      }

      @media screen and (min-width: 601px) and (max-width: 800px) {
        &:nth-child(3n + 1) {
          &:before {
            display: none;
          }
        }
      }

      @media screen and (max-width: 600px) {
        flex: 0 0 (100% / 3);
        img {
          width: 80px;
        }
      }

      @media screen and (max-width: 400px) {
        img {
          width: 70px;
        }
      }
    }
  }
}

.title {
  margin-right: 10px;
}

.logoList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  &_item {
    padding: 0 10px;
    flex: 0 0 100px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      margin: auto;
      filter: grayscale(1);
    }

    & + & {
      &:before {
        content: '';
        width: 1px;
        height: 100%;
        background-color: #e5e5e5;
        position: absolute;
        top: 0;
        left: -1px;
      }
    }
  }
}

.inlineLogos {
  overflow: auto !important;
  overflow-y: hidden !important;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.inLineViewAll {
  border-left: 1px solid #e5e5e5;
  border-radius: 0;
  padding-left: 20px;
  & > a {
    height: 100%;
  }
}

.multiRows {
  &_item {
    & + & {
      &:before {
        display: none;
      }
    }
  }
}

.linkWrapper {
  text-align: right;
  &__text {
    color: theme.$blue;
    &:hover {
      text-decoration: underline;
    }
  }
}
